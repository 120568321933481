import React from 'react'
import "./sustainableInitiatives.css"
import Initiative1 from "../../images/sustainableInitiatives1.png"

export default function SustainableInitiatives() {
  return (
    <div>
      <div className="susInitiativeslayout-219">
            <div className="susInitiativescontainer2">
              {/* <img className="susInitiativescontainer-child" alt="" src="/ellipse-7@2x.png" /> */}
              <img
                className="susInitiativesplaceholder-image-icon2"
                alt=""
                src={Initiative1}
              />
              <div className="susInitiativestabs-menu">
                <b className="susInitiativesheading3">Economic</b>
                <div className="susInitiativestext12">
                  CIC believes in value generation and its distribution amongst
                  the widest possible cross section of the stakeholder
                  community.
                </div>
                <div className="susInitiativestab-horizontal">
                  <div className="susInitiativesheading4">Local hiring</div>
                  <div className="susInitiativestext13">
                    CIC Group strives to develop the local economy in each of
                    its areas of operation, by increasing local employment
                    figures. CIC’s practice of recruiting from the locales where
                    its businesses operate, generate a multitude of economic
                    benefits within those communities. CIC also commits to
                    remunerating its staff well above the minimum thresholds
                    stipulated for each industry that it operates in.
                  </div>
                </div>
                <div className="susInitiativestab-horizontal1">
                  <div className="susInitiativesheading4">Local sourcing</div>
                  <div className="susInitiativestext13">
                    CIC also understands that purchasing locally, gives rise to
                    economic benefits. The company therefore aims to purchase
                    from local suppliers wherever possible, while maintaining
                    the quality and standards required.
                  </div>
                </div>
              </div>
              <div className="susInitiativescontent10" />
            </div>
          </div>
    </div>
  )
}
