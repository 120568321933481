import React from "react";
import "./sustainabilityBuildingFuture.css";
import BuildingFutureIcon from "../../images/susBuildingFuture.png";
import BuildingFutureIcon1 from "../../images/vector-6.svg";

export default function SustainabilityBuildingFuture() {
  return (
    <div>
      <div className="susBuildingFuture-layout-4">
        <div className="susBuildingFuture-container6">
          <div className="susBuildingFuture-content17">
            <div className="susBuildingFuture-content18">
              <div className="susBuildingFuture-section-title1">
                <div className="susBuildingFuture-content19">
                  <div className="susBuildingFuture-heading">
                    <p className="susBuildingFuture-given-the-industries">{`Research & `}</p>
                    <p className="susBuildingFuture-given-the-industries">
                      Development
                    </p>
                  </div>
                  <div className="susBuildingFuture-text12">
                    As one of Sri Lanka’s leading blue-chip conglomerates, CIC
                    Holdings PLC has the ability to adapt with the customer and
                    the changing operating environment.
                  </div>
                </div>
              </div>
            </div>
            <div className="susBuildingFuture-actions2">
              <div className="susBuildingFuture-button3">
                <div className="susBuildingFuture-button-child" />
                <div className="susBuildingFuture-read-more">Read More</div>
                <img
                  className="susBuildingFuture-button-item"
                  alt=""
                  src={BuildingFutureIcon1}
                />
              </div>
            </div>
          </div>
          <img
            className="susBuildingFuture-placeholder-image-icon4"
            alt=""
            src={BuildingFutureIcon}
          />
        </div>
      </div>
    </div>
  );
}
