import React from "react";
import "./sustainabilityInnovativeSolutions.css";
import InnovativeSolutionImage1 from "../../images/susInnovativeSol.png";
import InnovativeSolutionImage2 from "../../images/vector-61.svg";

export default function SustainabilityInnovativeSolutions() {
  return (
    <div>
      <div className="susInnovativeSol-layout-192">
        <div className="susInnovativeSol-container2">
          <img
            className="susInnovativeSol-placeholder-image-icon4"
            alt=""
            src={InnovativeSolutionImage1}
          />
          <div className="susInnovativeSol-content20">
            <div className="susInnovativeSol-section-title1">
              <div className="susInnovativeSol-content19">
                <div className="susInnovativeSol-heading">CSR</div>
                <div className="susInnovativeSol-text48">
                  <span className="susInnovativeSol-cic-manussakema">
                    ‘CIC Manussakema’
                  </span>
                  <span className="susInnovativeSol-cic-manussakema">
                    {" "}
                    is a multi-dimensional CSR initiative consisting of several
                    programmes that are multifaceted in their nature and
                    approach.
                  </span>
                </div>
              </div>
            </div>
            <div className="susInnovativeSol-actions2">
              <div className="susInnovativeSol-button3">
                <div className="susInnovativeSol-button-child" />
                <div className="susInnovativeSol-read-more">Read More</div>
                <img
                  className="susInnovativeSol-button-item"
                  alt=""
                  src={InnovativeSolutionImage2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
