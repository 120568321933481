import React from 'react'
import "./sustainableEconomic.css"
import EconomicImage from "../../images/susEconomics.png"

export default function SustainableEconomic() {
  return (
    <div>
      <div className="susEconomic-layout-2191">
            <div className="susEconomic-container2">
              <img
                className="susEconomic-placeholder-image-icon2"
                alt=""
                src={EconomicImage}
              />
              <div className="susEconomic-tabs-menu">
                <b className="susEconomic-heading3">Environmental</b>
                <div className="susEconomic-text12">
                  CIC believes that the environment is an integral ‘pillar’ of
                  sustainability and acknowledges the absolute necessity to
                  manage both the use of resources as well as the impact our
                  business may exert on the environment.
                </div>
                <div className="susEconomic-tab-horizontal">
                  <div className="susEconomic-heading4">Material usage</div>
                  <div className="susEconomic-text13">
                    CIC Group recognizes that at times, environmental impacts
                    can occur from the material stage of its production process.
                    CIC is therefore mindful of the materials used, and
                    companies within the Group strive to use recycled input
                    materials in the production process wherever possible.
                  </div>
                </div>
                <div className="susEconomic-tab-horizontal1">
                  <div className="susEconomic-heading4">Energy consumption</div>
                  <div className="susEconomic-text13">
                    CIC Group believes in efficient use of energy, and is
                    committed to reducing consumption wherever possible. The
                    company strives to optimize its energy usage, and also takes
                    steps to use renewable energy sources where it can. This
                    effort can also serve to solve other environmental issues,
                    as seen in our agriculture sector where paddy husks, a waste
                    material from the production process, is used as a fuel
                    source.
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
