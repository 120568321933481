import React from 'react'
import "./sustainableSolutions.css"
import SolutionImage from "../../images/sustainableSolution1.png"

export default function SustainableSolutions() {
  return (
    <div>
       <div className="susSolutions-layout-6">
            <div className="susSolutions-container2">
              <div className="susSolutions-tabs-menu">
                <b className="susSolutions-heading11">Social</b>
                <div className="susSolutions-text22">
                  CIC believes in protecting the rights of society and thus
                  adopts a ‘People conscious’ approach in all its activities.
                </div>
                <div className="susSolutions-tab-horizontal7">
                  <div className="susSolutions-heading4">Health and Safety</div>
                  <div className="susSolutions-text13">
                    Health and safety of its workforce is of paramount
                    importance to the CIC Group. All employees are therefore
                    provided with necessary training including first aid, fire
                    safety, and necessary job related training in order to
                    ensure their health, safety, and wellbeing. All necessary
                    safety equipment is also provided to staff, depending on the
                    nature of their work.
                  </div>
                </div>
                <div className="susSolutions-tab-horizontal8">
                  <div className="susSolutions-heading4">Training and Development</div>
                  <div className="susSolutions-text13">
                    CIC Group recognizes the fundamental importance of training,
                    education, and development for the wellbeing and progress of
                    its employees, and each entity is committed to providing
                    timely and relevant training for their respective staff. All
                    entities within the Group strive to ensure that all
                    employees irrespective of staff grade have an equal
                    opportunity for training and development.
                  </div>
                </div>
              </div>
              <img
                className="susSolutions-placeholder-image-icon4"
                alt=""
                src={SolutionImage}
              />
            </div>
          </div>
    </div>
  )
}
