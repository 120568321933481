import React from 'react'
import "./sustainabilityJourney.css"
import JourneyImage1 from "../../images/susJourney1.png"
import JourneyImage2 from "../../images/susJourney2.png"
import JourneyImage3 from "../../images/susJourney3.png"
import JourneyImage4 from "../../images/susJourney4.png"
import JourneyImage5 from "../../images/susJourney5.png"
import JourneyImage6 from "../../images/susJourney6.png"

export default function SustainabilityJourney() {
  return (
    <div className='sustainability-Journey'>
      <div className='Journey-left-column'>
        <div className="Journey-button-parent">
          <div className="Journey-sustainability-reports1">
            Sustainability Reports
          </div>
        </div>
      </div>
      <div className='Journey-right-column'>
        <div className="Journey-sustainability-reports-child" />
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage1} />
          <div className="Journey-div">2013|14</div>
        </div>
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage2} />
          <div className="Journey-div">2014|15</div>
        </div>
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage3} />
          <div className="Journey-div">2015|16</div>
        </div>
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage4} />
          <div className="Journey-div">2016|17</div>
        </div>
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage5} />
          <div className="Journey-div">2017|18</div>
        </div>
        <div className="Journey-sustainability-report">
          <img className="Journey-sustainability-report-child" alt="" src={JourneyImage6} />
          <div className="Journey-div">2018|19</div>
        </div>
      </div>
    </div>
  )
}
