import React from 'react'
import "./sustainabilityEmpowerCommunity.css"

export default function SustainabilityEmpowerCommunity() {
  return (
    <div>
      <div className="susEmpowerCommunity-layout-42">
            <div className="susEmpowerCommunity-content2">
              <div className="susEmpowerCommunity-column2">
                <div className="susEmpowerCommunity-heading">
                  Sustainability has long been considered a way of life at CIC.
                </div>
              </div>
              <div className="susEmpowerCommunity-column3">
                <div className="susEmpowerCommunity-text">
                  <p className="susEmpowerCommunity-given-the-industries">
                    Given the industries that it operates in, the Company’s long
                    term success is tightly bound to the progress made in
                    society, as well as to the survival of the natural
                    environment as we know it. CIC is intrinsically linked with
                    rural society given the widespread out-grower models
                    operated by subsidiaries of the Group. In addition,
                    subsidiaries depend on the natural environment for a
                    multitude of essential raw materials required to produce its
                    products up to the standards and applications desired.
                  </p>
                  <p className="susEmpowerCommunity-given-the-industries">&nbsp;</p>
                  <p className="susEmpowerCommunity-given-the-industries">
                    CIC is therefore taking steps to protect the economy, social
                    fabric of society, and the environment, not only for the
                    benefit of its many stakeholders, but also in order to
                    protect its own future.
                  </p>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
