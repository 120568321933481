import React from 'react'
import "./sustainabilityMainBanner.css"
import SustainImage from "../../images/sustainabilityMainBanner.png"

export default function SustainabilityMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="sustainability-home-image" alt="" src={SustainImage} />
          <div className="sustainability-image-text-box">
            <div className="sustainability-image-background-text">
              <h1 className="sustainability-image-topic">
                Building a Sustainable Future
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
